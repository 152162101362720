import React from 'react'
import { compose } from 'recompose'
import { withAuthorization } from '../components/session'
import { Wrap, Title } from '../components/page/components'
import SEO from '../components/SEO'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'
import PassChangeForm from '../components/account/pass-change-form'

const AccountResetPass = ({ authUser }) => {
  const backUrl =
    location.state && location.state.prevPath ? location.state.prevPath : '/'

  return (
    <Wrap>
      <SEO title="Change Password" />
      <FocusHeader
        title="Account"
        subTitle="Change Password"
        titleLink="/account"
        backUrl={backUrl}
      />
      <FocusBody>
        <Title>Change Password</Title>
        <PassChangeForm authUser={authUser} />
      </FocusBody>
    </Wrap>
  )
}

const condition = authUser => !!authUser
const AccountWithAuth = compose(withAuthorization(condition))(AccountResetPass)
export default AccountWithAuth

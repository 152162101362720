import React, { useState } from 'react'
import {
  Button,
  FormBox,
  FormFields,
  FormField,
  FormCopy,
} from '../page/components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { Link } from 'gatsby'

import { withFirebase } from '../firebase'

const INITIAL_STATUS = {
  submitting: false,
  submitted: false,
  submitErrors: [],
}

const StyledTextField = styled(TextField)`
  ${props => props.theme.materialTextField()}
`

const userSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
})

const PassChangeForm = ({ authUser, firebase }) => {
  const [status, setStatus] = useState(INITIAL_STATUS)
  const { submitting, submitted, submitErrors } = status

  let content = null
  if (submitted && submitErrors.length === 0) {
    content = (
      <FormBox>
        <FormFields>
          <FormField width={`100%`}>
            <FormCopy>
              <p>
                A request to reset your password has been sent to{' '}
                {authUser.email}.
              </p>
            </FormCopy>
          </FormField>
        </FormFields>
      </FormBox>
    )
  } else {
    const errorContent =
      submitErrors.length > 0
        ? submitErrors.map((e, index) => <p key={`erorr-${index}`}>{e}</p>)
        : null

    const buttonText = submitting
      ? `Submitting Password Reset Request...`
      : `Submit Password Reset Request`

    content = (
      <Formik
        initialValues={{
          email: authUser.email,
        }}
        validationSchema={userSchema}
        onSubmit={() => {
          if (submitting) return false

          setStatus(prevState => ({
            ...prevState,
            submitting: true,
            submitErrors: [],
          }))

          firebase.auth
            .sendPasswordResetEmail(authUser.email)
            .then(function() {
              setStatus(prevState => ({
                ...prevState,
                submitting: false,
                submitted: true,
                submitErrors: [],
              }))
            })
            .catch(function(error) {
              const { message } = error
              setStatus(prevState => ({
                ...prevState,
                submitting: false,
                submitted: true,
                submitErrors: [message],
              }))
            })
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => (
          <FormBox processing={submitting}>
            <form onSubmit={handleSubmit}>
              <FormFields>
                <FormField width={`100%`}>
                  <FormCopy>
                    A reset password link will be sent to the email address
                    below.
                  </FormCopy>
                </FormField>
                <FormField width={`100%`}>
                  <StyledTextField
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    margin="normal"
                    disabled
                  />
                </FormField>
                {errorContent}
                <FormField marginRight={`50%`}>
                  <Button type="submit">{buttonText}</Button>
                </FormField>
                <FormField marginRight={`50%`}>
                  <FormCopy>
                    <p>
                      <Link to="/account">Back</Link>
                    </p>
                  </FormCopy>
                </FormField>
              </FormFields>
            </form>
          </FormBox>
        )}
      </Formik>
    )
  }

  return content
}

export default withFirebase(PassChangeForm)
